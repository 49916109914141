import React, { FunctionComponent } from "react"
import ArticleCard from "../../../components/ArticleCard"
import "../../../assets/styles/page/docArticle.scss"
import { graphql, Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo/seo"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const article: FunctionComponent = ({ data }) => {
  const articles = data.allContentfulArticlePage.nodes[0].articleList
  const MetaData = data.allContentfulArticlePage.nodes[0].seo

  return (
    <div>
      <SEO title={MetaData.title} description={MetaData.description} image={MetaData.image.fluid.src} />
      <div>
        <Layout>
          <div className="articles--page--cointainer">
            <div className="article--header">
              <h1 className="articles--title">Articles</h1>
              <p className="articles--title--subtitles">
                Explore our community features, case studies, opinion pieces, and more
              </p>
            </div>
            <div className="article--list">
              {articles.map(
                (article: {
                  titleOfArticle: any
                  metaTitle: any
                  id: any
                  metaDescription: any
                  bannerUrl: any
                  slug: any
                  contentful_id: any
                }) => {
                  const { titleOfArticle, metaTitle, metaDescription, bannerUrl, slug, contentful_id } = article
                  return (
                    <Link to={`/doc/article/doc-article/${slug}`} className="artile--card--wrapper" key={contentful_id}>
                      <ArticleCard
                        key={contentful_id}
                        articleImage={bannerUrl.fluid.src}
                        articleHeadtext={titleOfArticle}
                        articleSubtext={metaTitle}
                        meta={metaDescription}
                      />
                    </Link>
                  )
                }
              )}
            </div>
          </div>
        </Layout>
      </div>
    </div>
  )
}
export default article

export const query = graphql`
  query articleInfo {
    allContentfulArticlePage {
      nodes {
        seo {
          description
          title
          image {
            fluid {
              src
            }
          }
        }
        articleList {
          titleOfArticle
          metaDescription
          metaTitle
          slug
          contentful_id
          bannerUrl {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
